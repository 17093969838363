<template>
  <Layout>
    <router-view />
  </Layout>
</template>

<script>
import '@/assets/fonts/inter/inter.css'
import '@/assets/icons/phosphor/styles.min.css'
import '@/assets/template/css/all.min.css'
import '@/assets/css/style.css'
import '@/assets/template/js/app'
// import '@/assets/plugins/jquery/jquery'
// import '@/assets/plugins/webticker/webticker.min'

import Layout from '@/views/layout/Layout.vue'

export default {
  name: 'App',

  components: {
    Layout,
  },
}
</script>
