<template>
  <div class="page-content vh-100">
    <div class="content-wrapper">
      <div class="content-inner">
        <div class="content p-0">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<style>
@media (max-width: 990px) {
  .content {
    padding: 0;
  }
}
</style>
<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Layout',
  components: {
    Footer,
  },
}
</script>
