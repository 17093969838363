<template>
  <span class="product-sub">
    <div class="card-sub-queue">
      <div class="card-item-queue-number sub-item">
        <span class="card-item-queue-number-text sub-item">{{
          queue.currentPosition
        }}</span>
      </div>
      <div class="card-item-queue-data sub-item">
        <span
          class="card-item-queue-bg-transparence"
          :class="
            index % 2 == 0 ? 'primary-product-color' : 'secondary-product-color'
          "
        ></span>
        <div class="card-item-queue-licence-plate-text sub-item">
          {{ queue.licencePlate }}
        </div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  name: 'ProductSub',
  props: {
    index: {
      type: Number,
      required: true,
    },
    queue: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
}
</script>
