<template>
  <div class="product-column">
    <div
      class="card-product-name"
      :class="
        index % 2 == 0 ? 'primary-product-color' : 'secondary-product-color'
      "
    >
      <span class="product-title">{{ product.product }}</span>
    </div>
    <div>
      <!-- Mostra itens Carregando em sua própria célula -->
      <div
        class="row mt-4 mx-0 op-3 product-height"
        v-if="loadingItems.length > 0"
      >
        <ProductLoading
          v-for="queue in loadingItems"
          :key="queue.id"
          :queue="queue"
          :index="index"
          :numberLoading="loadingItems.length"
        />
      </div>
      <!-- Mostra até 2 itens Aguardando -->
      <template
        v-for="queue in waitingItems.slice(0, this.sizeOffset)"
        :key="queue.id"
      >
        <ProductMain :queue="queue" :index="index" />
      </template>
      <!-- Mostra itens Aguardando adicionais em uma célula ProductSub -->
      <div class="row" v-if="waitingItems.length > this.sizeOffset">
        <ProductSub
          v-for="queue in waitingItems.slice(this.sizeOffset)"
          :key="queue.id"
          :queue="queue"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import ProductMain from '@/components/ProductMain.vue'
import ProductSub from '@/components/ProductSub.vue'
import ProductLoading from '@/components/ProductLoading.vue'

export default {
  name: 'ProductColumn',
  props: {
    index: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    ProductMain,
    ProductSub,
    ProductLoading,
  },
  data() {
    return {
      name: 1,
      licensePlate: 0,
      phone: null,
    }
  },
  computed: {
    loadingItems() {
      return this.product.itens.filter((item) => item.status === 'L')
    },
    // Filtra os itens com status 'Aguardando'
    waitingItems() {
      return this.product.itens.filter((item) => item.status !== 'L')
    },
    sizeOffset() {
      return this.loadingItems.length > 0 ? 2 : 3
    },
  },
  watch: {},
}
</script>
