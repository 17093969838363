<template>
  <Weather v-show="showWeather" />
  <div class="d-flex p-2 align-items-center w-100 h-100">
    <div class="row w-100 h-100 align-items-center" v-if="queue.length === 0">
      <div>
        <h2 class="text-center mt-5">Sem carregamentos na fila</h2>
        <h4 class="text-center mb-5">
          Aguarde até um novo carregamento ser cadastrado.
        </h4>
      </div>
    </div>
    <div
      v-else
      class="row h-100"
      :class="queue.length < 2 ? 'w-50 mx-auto' : 'w-100'"
    >
      <ProductColumn
        v-for="(product, index) in queue"
        :key="product.idPrd"
        :product="product"
        :index="parseInt(index)"
        :style="getWidth()"
      ></ProductColumn>
    </div>
  </div>
</template>

<script>
import ProductColumn from '@/components/ProductColumn.vue'
import Weather from '@/components/Weather.vue'

export default {
  name: 'BasePage',
  data() {
    return {
      disableButton: false,
      queue: [],
      numberOfProducts: 0,
      showWeather: true,
      intervalId: 0,
    }
  },
  components: {
    ProductColumn,
    Weather,
  },
  methods: {
    async getQueues() {
      try {
        this.isLoadingProducts = true
        this.queue = await this.$store.dispatch('getQueues')
        this.numberOfProducts = this.queue.length
        this.isLoadingProducts = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    getWidth() {
      const width = 100 / this.numberOfProducts
      return `width: ${width}%`
    },
    // showOrHideWeather() {
    //   this.showWeather = true
    //   setTimeout(() => {
    //     this.showWeather = false
    //   }, 15000) // hide after 15 seconds

    //   this.intervalId = setInterval(() => {
    //     this.showWeather = true
    //     setTimeout(() => {
    //       this.showWeather = false
    //     }, 15000) // hide after 15 seconds
    //   }, 60000) // repeat every 60 seconds
    // },
  },
  watch: {
    numberOfProducts: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getWidth()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    setInterval(() => {
      this.getQueues()
    }, 30000)
    this.getQueues()
    // this.showOrHideWeather()
  },
}
</script>
