<template>
  <span class="product-sub col">
    <div class="card-sub-queue">
      <div class="card-item-loading-data sub-item m-1 loadingItem">
        <span
          class="card-item-queue-bg-transparence bg-success text-white sub-item"
        ></span>
        <div
          class="card-item-loading-licence-plate-text sub-item"
          :style="{ fontSize: calculateFontSize }"
        >
          {{ queue.licencePlate }}
        </div>
      </div>
    </div>
  </span>
</template>
<style>
.loadingItem {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script>
export default {
  name: 'ProductLoading',
  props: {
    index: {
      type: Number,
      required: true,
    },
    queue: {
      type: Object,
      required: true,
    },
    numberLoading: {
      type: Number,
      required: true,
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    calculateFontSize() {
      switch (this.numberLoading) {
        case 1:
          return '4rem'
        case 2:
          return '3rem'
        case 3:
          return '2rem'
        default:
          return '1rem'
      }
    },
  },
  watch: {},
}
</script>
