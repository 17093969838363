<template>
  <div class="w-100" id="weather-widget">
    <a
      ref="weatherwidget-io-js"
      class="weatherwidget-io"
      href="https://forecast7.com/pt/n21d25n45d00/lavras/"
      data-label_1="LAVRAS"
      data-label_2="PREVISÃO DO TEMPO"
      data-font="Roboto"
      data-icons="Climacons Animated"
      data-days="3"
      data-theme="weather_one"
      >LAVRAS PREVISÃO DO TEMPO</a
    >
  </div>
</template>
<style>
#weather-widget {
  position: relative;

  z-index: 999;
}
</style>
<script>
export default {
  name: 'Weather',

  mounted() {
    const fjs = this.$refs['weatherwidget-io-js']
    const js = document.createElement('script')
    js.id = 'weatherwidget-io-js'
    js.src = 'https://weatherwidget.io/js/widget.min.js'
    fjs.parentNode.insertBefore(js, fjs)
  },
}
</script>
