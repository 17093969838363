<template>
  <div
    class="navbar navbar-expand-lg border-bottom border-bottom-white border-opacity-10 navbar-dark"
    :style="{ backgroundColor: footerColor }"
  >
    <div class="container-fluid d-flex justify-content-center">
      <div class="time-nav" :style="{ backgroundColor: ascentColor }">
        <div>
          <p>
            <span class="time mb-0">{{ dateTime }}</span
            ><br />
            <span class="day mb-0">{{ dayOfWeek }}</span>
          </p>
        </div>
      </div>
      <div class="container-news" style="height: 36px">
        <div class="item-news">
          {{ message }}
        </div>
      </div>
      <div class="logo-nav" :style="{ backgroundColor: ascentColor }">
        <img
          src="@/assets/img/horizon_logo_white.png"
          alt="SN BRITAS"
          class="img-fluid"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.time-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 70px;
  min-width: 140px;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: #fff;
  font-style: italic;
  z-index: 1000;
}

.logo-nav {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 180px;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: #fff;
  font-style: italic;
  z-index: 1000;
}

.logo-nav img {
  max-width: 120px;
}

.time-nav .time {
  font-size: 1.7rem;
  font-weight: 800;
  color: #fff;
}

.time-nav .day {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
}
.container-news {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 70px;
  overflow: hidden;
  position: relative;
}

.item-news {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 24px;
  white-space: nowrap;
  position: relative;
}
</style>
<script>
import { nextTick } from 'vue'

export default {
  name: 'Footer',

  components: {},

  data() {
    return {
      timetoGetNews: 60000,
      dateTime: '00:00',
      dayOfWeek: 'DOM',
      footerColor: '#234454',
      ascentColor: '#2e718e',
      numberOfNews: 0,
      message: 'Sem noticias no momento.',
      itemNews: null,
      newsIteration: 0,
    }
  },

  methods: {
    async getMessages() {
      try {
        // console.log('Obtendo mensagens')
        this.isLoadingNews = true
        this.news = await this.$store.dispatch('getMessages')
        this.numberOfNews = this.news.length
        if (this.numberOfNews === 0) {
          this.message = 'Sem noticias no momento.'
        }
        const news = this.news.map((item) => item.message)
        this.message = news.join(' -|- ')

        this.isLoadingNews = false
        //  aguarda 3 segundos e inicia a animação
        setTimeout(() => {
          this.newIteration()
        }, 3000)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    newIteration() {
      // console.log('Iniciando nova iteração')
      const container = document.querySelector('.container-news')
      const itemNews = document.querySelector('.item-news')

      const windowWidth = window.innerWidth
      // console.log('Largura da janela:', windowWidth)

      const itemNewsWidth = itemNews.offsetWidth
      const time = (windowWidth + itemNewsWidth) / 100 // Ajuste para controlar a velocidade
      // console.log('Largura do item:', itemNewsWidth)

      itemNews.style.transition = `transform ${time}s linear`
      itemNews.style.transform = `translateX(${windowWidth}px)`

      setTimeout(() => {
        itemNews.style.transform = `translateX(-${itemNewsWidth}px)`
      }, 100)

      itemNews.ontransitionend = () => {
        // console.log('Fim da transição')
        itemNews.style.transition = ''
        itemNews.style.transform = `translateX(${windowWidth}px)`

        if (this.newsIteration === 3) {
          this.newsIteration = 0
          this.getMessages()
        } else {
          this.newsIteration = (this.newsIteration || 0) + 1
          setTimeout(() => {
            this.newIteration()
          }, 500) // Adiciona um delay antes de reiniciar para evitar loops rápidos
        }
      }
    },
    updateClock() {
      const now = new Date()
      let hours = now.getHours()
      let minutes = now.getMinutes()
      const day = now.getDay()

      const dayOfWeek = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB']

      // Adiciona um zero à esquerda se as horas ou minutos forem menores que 10
      hours = hours < 10 ? `0${hours}` : hours
      minutes = minutes < 10 ? `0${minutes}` : minutes

      this.dateTime = `${hours}:${minutes}`
      this.dayOfWeek = `${dayOfWeek[day]}`
    },
  },
  async mounted() {
    await nextTick()
    //  aguarda 3 segundos e inicia a animação
    this.getMessages()
    this.updateClock()
    setInterval(() => {
      this.updateClock()
    }, 5000)
    // Sempre que a animação do ticker terminar, atualiza a lista de mensagens
    // document.querySelector('.ticker').addEventListener('animationend', () => {
    //   this.getMessages()
    // })
  },
}
</script>
