<template>
  <div class="product-main">
    <div class="card-item-queue">
      <div class="card-item-queue-number">
        <span
          class="card-item-queue-number-text"
          v-html="defineCurrentPosition"
        ></span>
      </div>
      <div class="card-item-queue-data product-height" :class="defineHeight">
        <span :class="defineBackgroundColor"></span>
        <div
          v-if="queue.status == 'S'"
          class="card-item-top-message text-danger"
        >
          DIRIJA-SE À BALANÇA
        </div>
        <div
          v-if="queue.status == 'L'"
          class="card-item-top-message text-success"
        >
          CARREGANDO
        </div>
        <div class="card-item-queue-licence-plate-text">
          {{ queue.licencePlate }}
        </div>
        <div class="card-item-queue-info">
          <p class="mb-0">
            <span class="primary-text-color">Cliente: </span>
            <span class="secondary-text-color">{{ queue.client }}</span>
          </p>
          <p class="mb-1">
            <span class="primary-text-color">Motorista: </span>
            <span class="secondary-text-color">{{ queue.driver }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductMain',
  props: {
    index: {
      type: Number,
      required: true,
    },
    queue: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    defineHeight() {
      if (this.queue.currentPosition === 0) {
        return 'height-index-0'
      }
      return ''
    },
    defineBackgroundColor() {
      if (this.queue.status === 'W') {
        return this.index % 2 === 0
          ? 'primary-product-color card-item-queue-bg-transparence'
          : 'secondary-product-color card-item-queue-bg-transparence'
      }
      if (this.queue.status === 'S') {
        return 'bg-danger bg-opacity-50 card-item-queue-bg text-white'
      }
      if (this.queue.status === 'L') {
        return 'bg-success bg-opacity-50 card-item-queue-bg text-white'
      }
      return 'card-item-queue-bg bg-dark'
    },
    defineCurrentPosition() {
      if (this.queue.currentPosition > 0) {
        return this.queue.currentPosition
      }
      if (this.queue.status === 'S') {
        return '<i class="ph-warning"></i> '
      }
      if (this.queue.status === 'L') {
        return '<i class="ph-barricade"></i> '
      }
      return ''
    },
  },
  watch: {},
}
</script>
